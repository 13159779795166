import {
  faSpinner
} from "@fortawesome/free-solid-svg-icons"
import {library} from "@fortawesome/fontawesome-svg-core"

const Icons = () => {
  return library.add(
    faSpinner
  )
}

export default Icons