import React, { useState } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SkuGenerator = () => {

  const [formData, setFormData] = useState({
    numSkus: '',
    initials: '',
    prefix: '',
    fileName: 'prinicity_skus',
    mode: 'single'
  })
  const [singleSku, setSingleSku] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleNumSkusChange = e => {
    setFormData({
      ...formData,
      numSkus: parseInt(e.target.value)
    })
  }

  const handleInitialsChange = e => {
    setFormData({
      ...formData,
      initials: e.target.value.substring(0, 3).toUpperCase()
    })
  }

  const handlePrefixChange = e => {
    setFormData({
      ...formData,
      prefix: e.target.value
    })
  }

  const handleFileNameChange = e => {
    setFormData({
      ...formData,
      fileName: e.target.value.replace('.', '')
    })
  }

  const handleRadioChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const getIds = async () => {
    let error = false
    const ids1 = await axios.get('https://prinicity-arbitrage.firebaseio.com/ids.json')
      .then(res => res.data)
      .catch(err => {
        alert(err.message)
        error = true
      })
    if (error) {
      setLoading(false)
      return 'error'
    }
    const ids2 = await axios.get('https://prinicity-arbitrage.firebaseio.com/ids2.json')
      .then(res => res.data)
      .catch(err => {
        alert(err.message)
        error = true
      })
    if (error) {
      setLoading(false)
      return 'error'
    }
    const existingIds = ids1.concat(ids2);

    const makeIds = (qty, totalLength, prefix = 'PR00') => {
      // eslint-disable-next-line
      for (let _ of Array(qty)) {
        return [...Array(qty)].map(() => {
          const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
          let id
          do {
            let text = prefix
            for (let i = 0; i < totalLength - prefix.length; i++) {
              text += possible.charAt(Math.floor(Math.random() * possible.length))
            }
            id = text
          } while (existingIds.includes(id))
          return id
        })
      }
    }

    const ids = makeIds(formData.mode === 'single' ? 1 : formData.numSkus, 14, `${formData.prefix}PR${formData.initials}`)

    const newExistingIds = ids2.concat(ids);
    await axios.patch('https://prinicity-arbitrage.firebaseio.com/.json', JSON.stringify({ids2: newExistingIds}))
    .catch(err => {
      alert(err.message)
      error = true
    })
    if (error) {
      setLoading(false)
      return 'error'
    } else {
      return ids
    }
  }

  const handleFileGeneration = async e => {
    e.preventDefault()
    setLoading(true)

    const { fileName } = formData

    if (formData.initials.length !== 2 && formData.initials.length !== 3) {
      alert('Please enter valid initials.')
      setLoading(false)
      return
    } else if (formData.numSkus < 1) {
      alert('Please choose a positive integer.')
      setLoading(false)
      return
    } else if (fileName.length < 1) {
      alert('Please enter a valid file name.')
      setLoading(false)
      return
    }

    const ids = await getIds()
    if (ids === 'error') return

    const text = ids.join().replace(/,/g, "\n")

    const element = document.createElement('a')
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
    element.setAttribute('download', fileName + '.csv')
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)

    setLoading(false)
  }

  const handleCopy = async e => {
    e.preventDefault()
    setLoading(true)

    if (formData.initials.length !== 2 && formData.initials.length !== 3) {
      alert('Please enter valid initials.')
      setLoading(false)
      return
    }

    const ids = await getIds()
    const id = ids[0]
    if (id === 'error') return
    setSingleSku(id)

    const element = document.createElement('textarea')
    element.value = id
    document.body.appendChild(element)
    element.select()
    document.execCommand('copy')
    document.body.removeChild(element)

    setLoading(false)
  }

  return (
    <div className='SkuGenerator'>
      <h1>SKU Generator</h1>
      <h3>Generates as many SKUs as you would like.</h3>
      <p>Uses the format: "PR" then your initials, then a random 10 character string.</p>
      <p>e.g. PRPSgUE10jbPd7</p>
      <form id="generateIdsForm">
        <div className="question">
          <label htmlFor="prefix">Prefix (if any)</label>
          <input type="text" name="prefix" value={formData.prefix} onChange={handlePrefixChange} />
        </div>
        <div className="question">
          <label htmlFor="initials">Your Initials</label>
          <input type="text" name="initials" value={formData.initials} onChange={handleInitialsChange} />
        </div>
        <div className="question radio">
          <label>
            <input type="radio" name="mode" value="single" checked={formData.mode === 'single' ? true : false} onChange={handleRadioChange} />
            <span>Copy a single SKU to your clipboard</span>
          </label>
          <label>
            <input type="radio" name="mode" value="multiple" checked={formData.mode === 'multiple' ? true : false} onChange={handleRadioChange} />
            <span>Download a spreadsheet of SKUs</span>
          </label>
        </div>
        {formData.mode === 'single' ? 
          <>
            {singleSku ? <p className="centered-text">Your Generated SKU: <code>{singleSku}</code></p> : null}
            {loading ? <button disabled={true}><FontAwesomeIcon icon='spinner' spin={true} /></button>
            : <button onClick={handleCopy}>Copy {singleSku ? 'Another' : 'One'} SKU</button>}
          </> : <>
            <div className="question">
              <label htmlFor="number">Number of SKUs to Generate</label>
              <input type="number" name="numSkus" value={formData.numSkus} onChange={handleNumSkusChange} />
            </div>
            <div className="question">
              <label htmlFor="fileName">File Name</label>
              <input type="text" name="fileName" value={formData.fileName} onChange={handleFileNameChange} />
            </div>
            {loading ? <button disabled={true}><FontAwesomeIcon icon='spinner' spin={true} /></button>
            : <button onClick={handleFileGeneration}>Generate</button>}
        </>}
      </form>
    </div>
  )
}

export default SkuGenerator