import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Icons from '../config/icons'

import Nav from './Nav'
import Home from './routes/Home'
import SkuGenerator from './routes/SkuGenerator'
import Bookmarklets from './routes/Bookmarklets'

Icons()

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Nav />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/sku-generator" component={SkuGenerator} />
          <Route path="/bookmarklets" component={Bookmarklets} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
