import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {

  

  return (
    <div className='Home'>
      <h1>Quick Links</h1>
      <Link to='/sku-generator'>SKU Generator</Link><br/>
      <Link to='/bookmarklets'>Bookmarklets</Link><br/>
    </div>
  )
}

export default Home