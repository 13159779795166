import React from 'react'
import { NavLink } from 'react-router-dom'

const Nav = () => {
  return (
    <nav className='Nav'>
      <NavLink exact to='/' className='nav-link' activeClassName='nav-link-active'>Home</NavLink>
      <NavLink to='/sku-generator' className='nav-link' activeClassName='nav-link-active'>SKU Generator</NavLink>
      <NavLink to='/bookmarklets' className='nav-link' activeClassName='nav-link-active'>Bookmarklets</NavLink>
    </nav>
  )
}

export default Nav