import React from 'react';

function Bookmarklets() {
  return (
    <div className='Bookmarklets'>
      <h1>Bookmarklets</h1>
      <div className='bookmarklet'>
        {/* eslint-disable-next-line */}
        <a className="a-button" href='javascript:(function(){s=document.getElementsByClassName("prod-SecondaryInfo")[0].lastChild;u=s.attributes.content.value;s.innerHTML=u;t=document.createElement("textarea");document.body.appendChild(t);t.textContent=u;t.select();document.execCommand("copy");t.remove();})();'>Walmart UPC</a>
        <p>On a product page on Walmart.com, it will display the UPC to the right of the Walmart # and copy it to your clipboard.</p>
      </div>
      <div className='bookmarklet'>
        {/* eslint-disable-next-line */}
        <a className="a-button" href='javascript:(function(){r=/\/B[a-zA-Z0-9]{9}(\/|$)/;a=prompt("ASIN");if(r.test("/"+a)){window.open("https://blog.solone.us/random/?ad="+a,"_blank")}else{alert("Invalid ASIN")};})();'>Solone Blog</a>
        <p>Propts for an ASIN, then opens a new tab with the Solone Blog with an ad for the provided ASIN.</p>
      </div>
    </div>
  )
}

export default Bookmarklets;